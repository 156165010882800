/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface GreyBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_GreyBlocks
}

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding: 20px 30px;
`

const GreyBlocks: React.FC<GreyBlocksProps> = ({ fields }) => (
  <section className="py-4">
    <div className="mb-4">
      <ParseContent content={fields.description || ''} />
      <div className="row pt-4">
        {fields.blocks?.map((b, index) => (
          <div className="col-lg-4" key={index}>
            <Block>
              <ParseContent content={b?.description || ''} />
            </Block>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default GreyBlocks
